<template lang="pug">
  .content-wrapper-for-branding__wrapper
    #content-wrapper-for-branding.content-wrapper-for-branding
</template>

<script lang="ts">
  // Компонент создан для стилизации(скрытия) блока div,
  // следующего за текущим. Поэтому нет scoped в style,
  // в отличии от родителя. Блок div высотой 250px добавляет
  // adfox, поэтому нужны такие костыли, чтобы его скрыть
  // при открытии окна поиска
  export default defineNuxtComponent({
    name: 'ContentWrapperForBranding',
  });
</script>

<style lang="scss">
  .content-wrapper-for-branding {
    .is-search & + div[style*='height:'] {
      display: none;
    }
  }

  // fix LH-1201
  // Иногда брендирование вставляется без удаления старого.
  // Это приводит к тому, что отступ до хеадера удваивается.
  // Поправить не получилось, поэтому скрыл все div элементы начиная с 3ьего.
  // Пример:
  // div.content-wrapper-for-branding__wrapper
  //   div#content-wrapper-for-branding.content-wrapper-for-branding
  //   div(style="height: 250px;")
  //   div(style="height: 250px;") HIDE
  .content-wrapper-for-branding__wrapper {
    > div:nth-child(n + 3) {
      display: none;
    }
  }
</style>
